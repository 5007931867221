import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { getFirestore ,query, where} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { collection, getDocs } from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyAWfOHaEHLAq-CooUocKyXn064ZBs7wDGU",
    authDomain: "fakenail-in.firebaseapp.com",
    projectId: "fakenail-in",
    storageBucket: "fakenail-in.appspot.com",
    messagingSenderId: "387250771904",
    appId: "1:387250771904:web:1c4d1d54f98f8d67635a25",
    measurementId: "G-9FMY9SMHFZ"
};
const app = await initializeApp(firebaseConfig);
const db =await getFirestore(app);

async function getproducts(category) {
    // Function implementation

    const q = query(collection(db, "products"), where("Category", "array-contains", category));
    const productlist = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        productlist.push(doc.data());
    });
    return (productlist)

}

function createCard(id,title,price,imagepath,extraimagepath,nailqty){

    var extraimagepathstring = extraimagepath.join(';');

    var card = ` <div id="${id}" class="card  mt-4  "  data-bs-toggle="modal" data-bs-target="#cardModal" data-id="${id}" data-image-path="${imagepath}" data-title="${title}" data-price="${price}" data-nailqty="${nailqty}"    data-extra-image-path="${extraimagepathstring}" >
            <img data-splide-lazy="${imagepath}" class="card-img-top px-1 px-md-3 px-md-4" style="height: auto; width: auto;" alt="best artifitial nails | fake nails | fake nail india">
            <div class="card-body ">
                <div class="row ">
                    <div class="col-12 d-flex justify-content-between">
                        <h5 class="card-title">${title}</h5>
                        <p class="card-text justify-content-end">₹ ${price}</p>
                    </div>
                    <div class="col-12  ">
                         <button class="btn w-100 text-wrap btn-outline-danger"><img  src="data/icons/cart.svg" alt="cart"></button>
                    </div>
                </div>
            </div>
    </div>`
    var parser = new DOMParser();
    var doc = parser.parseFromString(card, 'text/html');
    return doc.body.firstChild;
}


function fillsplide(elementId,cardList){
    console.log("cardList",cardList);
    var carouselInnerLarge = document.getElementById(elementId);

    for (var i = 0; i < cardList.length; i += 1) {
        var carouselWrapper = document.createElement('li');
        carouselWrapper.classList.add('splide__slide');

        var j = i;
        carouselWrapper.appendChild(createCard(cardList[j].ProductId,cardList[j].ProductTitle, cardList[j].ProductPrice, cardList[j].Image,cardList[j].downloadUrlExtra,cardList[j].NailsQty));

        carouselInnerLarge.appendChild(carouselWrapper);
    }
}


async function addContentLargeToCarousel() {

    var cardList = await getproducts("Bestseller");

    fillsplide('ExclusiveIndex',cardList);
}
async function addContentDesignerLargeToCarousel() {

    var cardList = await getproducts("Exclusive");

    fillsplide('DesignerIndex',cardList);
}









document.addEventListener('DOMContentLoaded', async function() {

    await addContentLargeToCarousel();
    await addContentDesignerLargeToCarousel();
    function initializeSplide(selector) {
        var splideOptions = {
            type: 'loop',
            pagination: false,
            arrows: true,
            snap: true,
            perPage: 3,
            lazyLoad: 'nearby',
            preloadPages: 2,


            breakpoints: {
                992:{
                    perPage:3
                },
                768: {
                    perPage: 2
                },
                400: {
                    perPage: 1
                }
            }
        };


        var splide = new Splide(selector, splideOptions);
        splide.mount();


        // Return the initialized Splide instance
        return splide;
    }

    // Initialize Splide sliders
    var splide2 = initializeSplide('#splideIndex2');
    var splide21 = initializeSplide('#splideIndex21');
     var splideRoll2 = new Splide("#splideRoll2",{
        type: 'loop',
        drag: 'free',
        pagination: false,
        snap: true,
        arrows: false,
        perPage: 4,
        padding: '1rem',
        gap: '1rem',
        autoScroll: {
            speed: 0.5,
        },
        breakpoints: {
            992:{
                perPage:3
            },
            548: {
                perPage: 2
            },
            400: {
                perPage: 1
            }
        }
    });
     splideRoll2.mount({AutoScroll});


    var splideRoll321 = new Splide("#splideRoll321",{
        type: 'loop',
        drag: 'free',
        perPage: 5,
        arrows: false,
        pagination: false,
        autoScroll: {
            speed: 1,
        },
        breakpoints: {
            1200:{
                perPage:4
            },
            992:{
                perPage:3
            },

            548: {
                perPage: 1
            }
        }
    });
    splideRoll321.mount({AutoScroll});

    var splideIndex9 = new Splide("#splideIndex9",{
        perPage: 4,
        arrows: false,
        pagination: false,
        padding: '1rem',
        rewind:false,

        gap: '1rem',

        breakpoints: {
            1200:{
                perPage:3,
                type: 'loop',
                drag: 'free',
                rewind:true,
                autoScroll: {
                    speed: 0.5,

                }
            },
            992:{
                perPage:2,
                type: 'loop',
                drag: 'free',
                rewind:true,

                autoScroll: {
                    speed: 0.5,
                }
            },

            548: {
                perPage: 1,
                type: 'loop',
                drag: 'free',
                rewind:true,

                autoScroll: {
                    speed: 0.5,
                }

            }
        },

        });
    splideIndex9.mount({AutoScroll});
    new Splide("#splideRoll19",{
        type   : 'loop',
        perPage: 1,
        arrows: true,
        pagination: true,
        padding: '1rem',
        gap: '1rem',
        autoplay: true,

    }).mount();
});
